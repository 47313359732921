:root {
  --cnvs-themecolor: #fd5d3d;
  --cnvs-themecolor-rgb: 253, 93, 61;
  --cnvs-primary-font: "paralucent-text", sans-serif;
  --cnvs-body-font: "paralucent", sans-serif;
  --bs-bg-opacity: 1;
}

#header {
  --cnvs-primary-menu-font-size: .875rem;
  --cnvs-primary-menu-submenu-font-size: .875rem;
  --cnvs-primary-menu-submenu-font-weight: 500;
  --cnvs-primary-menu-submenu-hover-font-weight: 600;
  --cnvs-primary-menu-submenu-tt: none;
  --cnvs-primary-menu-hover-color: #111;
}

.is-expanded-menu .menu-link {
  --cnvs-primary-menu-padding-x: 0;
}

.is-expanded-menu .menu-container > .menu-item {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.is-expanded-menu .menu-container > .menu-item:not(.sub-menu) > .menu-link:after {
  content: "";
  background-color: var(--cnvs-themecolor);
  transform-origin: center;
  width: 110%;
  height: 2px;
  margin-left: -55%;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: scaleX(0);
}

.is-expanded-menu .menu-container > .menu-item:hover > .menu-link:after, .is-expanded-menu .menu-container > .menu-item.current > .menu-link:after {
  transform: scaleX(1);
}

.is-expanded-menu .sub-menu-container {
  border-radius: .25rem;
  box-shadow: 0 20px 50px 10px #0000000d;
}

.h-bg-tranparent:hover {
  background: none !important;
}

#logo a {
  letter-spacing: -1px;
}

.button[class*="bg-opacity-"]:hover {
  --bs-bg-opacity: 1;
  color: #fff !important;
}

.header-misc-icon a:hover {
  color: var(--cnvs-themecolor);
}

.speaker-gallery img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  height: 100%;
}

.top-search-open .top-search-parent .header-misc > #top-cart {
  opacity: 1;
  color: #fff;
}

.dotted-bg {
  --cnvs-dotted-opacity: .7;
  --cnvs-dotted-top: 0;
  --cnvs-dotted-right: 0;
  --cnvs-dotted-left: auto;
  --cnvs-dotted-size: 190px;
}

.dotted-bg.pos-left {
  --cnvs-dotted-right: auto;
  --cnvs-dotted-left: 0;
}

.dotted-bg:before {
  content: "";
  top: var(--cnvs-dotted-top);
  left: var(--cnvs-dotted-left);
  right: var(--cnvs-dotted-right);
  width: var(--cnvs-dotted-size);
  height: var(--cnvs-dotted-size);
  background-position: center;
  background-size: 20px 20px;
  background-image: radial-gradient(rgba(var(--cnvs-themecolor-rgb), var(--cnvs-dotted-opacity)) 25%, transparent 25%);
  z-index: auto;
  display: block;
  position: absolute;
  transform: translate(30px, -30px);
}

.top-account .dropdown-menu {
  border-color: #eee;
  border-top: 2px solid var(--cnvs-themecolor);
  box-shadow: 0 20px 50px 10px #0000000d;
}

.top-cart-open a, .top-account a.show {
  color: var(--cnvs-themecolor);
}

.sub-menu-container .menu-item > .menu-link, .sub-menu-container .menu-item:hover > .menu-link {
  background-color: #0000;
  font-family: var(--cnvs-primary-font) !important;
}

.noice-effect:before {
  content: "";
  opacity: .6;
  background-image: url("noise-paatern.d07feaf1.webp");
  background-repeat: repeat;
  background-size: auto;
  width: 300%;
  height: 300%;
  animation: 8s steps(10, end) infinite noiceAnim;
  position: absolute;
  top: -100%;
  left: -100%;
}

@keyframes noiceAnim {
  0%, to {
    transform: translate(0);
  }

  10% {
    transform: translate(-5%, -10%);
  }

  20% {
    transform: translate(-15%, 5%);
  }

  30% {
    transform: translate(7%, -25%);
  }

  40% {
    transform: translate(-5%, 25%);
  }

  50% {
    transform: translate(-15%, 10%);
  }

  60% {
    transform: translate(15%);
  }

  70% {
    transform: translateY(15%);
  }

  80% {
    transform: translate(3%, 35%);
  }

  90% {
    transform: translate(-10%, 10%);
  }
}

.swiper_wrapper {
  --swiper-height: 1000px;
  --swiper-thumbs-height: 70px;
  --swiper-thumbs-gap: 10px;
  --swiper-navigation-size: 24px;
  min-height: var(--swiper-height);
}

.swiper_wrapper .swiper-container {
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
}

.swiper-slide-bg {
  z-index: auto;
  height: auto;
  min-height: 440px;
  position: relative;
  top: auto;
  left: auto;
}

.swiper-thumb {
  height: auto;
  padding: var(--swiper-thumbs-gap) 0;
  text-align: center;
  cursor: pointer;
  width: calc(100% - calc(var(--swiper-navigation-size)  + 60px));
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
}

.swiper-thumb .swiper-slide, .swiper-thumb .swiper-slide img {
  width: 270px;
  height: var(--swiper-thumbs-height);
  -o-object-fit: cover;
  object-fit: cover;
}

.swiper-thumb .swiper-slide {
  filter: grayscale();
  opacity: .4;
  flex-grow: 1;
  flex-basis: 0;
}

.swiper-thumb .swiper-slide-thumb-active {
  filter: grayscale(0);
  opacity: 1;
}

.circle-wave {
  ---circle-wave-size: calc(30px + 1vw);
  ---circle-wave-font-size: calc(.75rem + 2vw);
  ---circle-wave-ripple-size: 10px;
  height: var(---circle-wave-size);
  width: var(---circle-wave-size);
  border-radius: 100%;
  margin-top: 4%;
}

.circle-wave i {
  font-size: var(---circle-wave-font-size);
  background: var(--cnvs-themecolor);
  color: #fff;
  text-align: center;
  height: var(---circle-wave-size);
  width: var(---circle-wave-size);
  line-height: var(---circle-wave-size);
  z-index: 1;
  border-radius: 100%;
  position: relative;
}

.circle-wave:before, .circle-wave:after {
  content: "";
  opacity: 0;
  border: var(---circle-wave-ripple-size) solid rgba(var(--cnvs-themecolor-rgb), .75);
  z-index: auto;
  border-radius: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation-name: ripple;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(.65, 0, .34, 1);
  animation-iteration-count: infinite;
  animation-delay: 0s;
  display: flex;
  position: absolute;
  inset: 0;
}

.circle-wave:before {
  animation-delay: .5s;
}

.entry .read-more i {
  font-size: 85%;
}

.entry:hover .read-more i {
  transform: translateX(4px);
}

.subscribe-widget input::placeholder {
  color: #aaa;
  font-size: 85%;
}

.subscribe-widget input::placeholder {
  color: #aaa;
  font-size: 85%;
}

#gotoTop {
  --cnvs-gotoTop-stroke-color: var(--cnvs-themecolor);
  --cnvs-gotoTop-stroke-size: 32px;
  --cnvs-gotoTop-stroke-width: 4px;
  --cnvs-scroll-progress-range: calc((var(--cnvs-body-height)  - var(--cnvs-viewport-height)) / var(--cnvs-body-height));
  --cnvs-gotoTop-scroll: calc(260 - (260 * (var(--cnvs-scroll-end) / var(--cnvs-scroll-progress-range))));
  background-color: #0000 !important;
}

#gotoTop:before {
  content: "";
  width: calc(var(--cnvs-gotoTop-stroke-size)  + calc(var(--cnvs-gotoTop-stroke-width) * .5));
  height: calc(var(--cnvs-gotoTop-stroke-size)  + calc(var(--cnvs-gotoTop-stroke-width) * .5));
  font-family: bootstrap-icons;
  line-height: calc(var(--cnvs-gotoTop-stroke-size)  - 1px);
  color: var(--cnvs-contrast-900);
  z-index: -1;
  border: 2px solid #0000001a;
  border-radius: 50%;
  margin-top: 2px;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#gotoTop circle {
  stroke: var(--cnvs-gotoTop-stroke-color);
  stroke-width: var(--cnvs-gotoTop-stroke-width);
  transform-origin: center;
  stroke-dashoffset: var(--cnvs-gotoTop-scroll);
  stroke-dasharray: 255 255;
  z-index: 1;
  transform: rotate(-90deg);
}

.btn-close {
  font-size: .75rem;
}

.widget_nav_menu li a, .widget_links li a, .widget_meta li a, .widget_archive li a, .widget_recent_entries li a, .widget_categories li a, .widget_pages li a, .widget_rss li a, .widget_recent_comments li a {
  font-family: var(--cnvs-primary-font);
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(.75, .75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}

@media (width >= 768px) {
  .swiper_wrapper {
    --swiper-height: 1000px;
  }

  .swiper-slide-info {
    margin-top: var(--swiper-thumbs-height);
  }

  .swiper-slide-bg {
    min-height: 1000px;
  }
}

@media (width >= 992px) {
  .text-stretch {
    z-index: 1;
    width: calc(100% + 200px);
    position: relative;
  }

  .section-1-bg {
    background: #f5f5f5 url("wide_bg.b129c9c4.webp") 0 100% / cover no-repeat;
  }

  .circle-wave {
    ---circle-wave-size: calc(40px + 4vw);
  }
}
/*# sourceMappingURL=index.48a4d097.css.map */
